import {I18n} from "i18n-js";

export const i18nProperty = new I18n({
  "en": {
    review: {
      owner: "Owner",
      tenant: "Tenant",
      "former-resident": "Former Resident",
      "real-estate-agent": "Real Estate Agent"
    },
    rating: {
      excellent: "Excellent",
      satisfied: "Satisfied",
      average: "Average",
      poor: "Poor",
      terrible: "Terrible"
    },
    ratingType: {
      'connectivity_rating': 'Transport & Commute',
      'lifestyle_rating': 'Living Standards & Lifestyle',
      'safety_rating': 'Safety & Peace of Mind',
      'environment_rating': 'Environment'
    },
    ratingTypeSubName: {
      'connectivity_rating': 'How are the public transports and traffic situation ?',
      'lifestyle_rating': 'How would you describe the overall quality of life and available services?',
      'safety_rating': 'Do you feel safe at all times?',
      'environment_rating': 'How would you rate the cleanliness and environmental quality?'
    },
    year: "%{year} year",
    years: "%{year} years",
    "more-than-years": "More than %{year} years",
    "image-invalid": "The image is invalid. Please remove it",
    "district": "District",
    "province": "Province",
    "city": "City",
    'dropzone': {
      'file-too-big': "The file is too big. Please upload file less than %{size} MB",
      'invalid-file-type': "Invalid file type. Please upload only image file",
      'max-file-upload': "You can upload maximum %{files} files"
    },
    success: "Successfully",
    'review-send-success': 'Your review has been sent successfully.\n Thank you for your review.',
    'ok': 'OK',
    filter: {
      'newest': 'Newest',
      'with-photos': 'With Photos',
      'negative-first': 'Negative First',
      'positive-first': 'Positive First',
    },
    'review-not-found-negative-first': 'No negative review found',
    'review-not-found': 'No review found',
    'review-not-found-recommended': 'No recommended review found',
    'review-not-found-with-photos': 'No review with photos found',
    'something-wrong': 'Something went wrong, Could you try again.',
    'question-send-success': 'Your question has been sent successfully. <br/>Thank you for your question.',
    'answer-send-success': 'Your answer has been sent successfully. <br/> Thank you for your answer.',
    'like-count': '%{count} Up-vote',
    'like': 'Up-vote',
    'please-select-location': 'Please select location'
  },

  "ar": {
    review: {
      owner: "المالك",
      tenant: "المستأجر",
      "former-resident": "الساكن السابق",
      "real-estate-agent": "وكيل عقاري"
    },
    rating: {
      excellent: "ممتاز",
      satisfied: "راضي",
      average: "متوسط",
      poor: "ضعيف",
      terrible: "فظيع"
    },
    ratingType: {
      'connectivity_rating': 'النقل والتنقلات',
      'lifestyle_rating': 'مستويات المعيشة ونمط الحياة',
      'safety_rating': 'السلامة وراحة البال',
      'environment_rating': 'بيئة'
    },
    ratingTypeSubName: {
      'connectivity_rating': 'كيف هي وسائل النقل العام وحالة المرور؟',
      'lifestyle_rating': 'كيف تصف نوعية الحياة بشكل عام والخدمات المتاحة؟',
      'safety_rating': 'هل تشعر بالأمان في كل الأوقات؟',
      'environment_rating': 'ما هو تقييمك للنظافة وجودة البيئة؟'
    },
    year: "%{year} عام",
    years: "%{year} سنوات",
    "more-than-years": "أكثر من {year}% سنوات",
    "image-invalid": "الصورة غير صالحة يمكنك إزالتها",
    "district": "منطقة",
    "province": "محافظة",
    "city": "مدينة",
    'dropzone': {
      'file-too-big': "الملف كبير جدا. يرجى تحميل ملف أقل من {size}% MB",
      'invalid-file-type': "نوع الملف غير صالح. يرجى تحميل ملف صورة فقط",
      'max-file-upload': "يمكنك تحميل ما يصل إلى {files}% ملفات"
    },
    success: "بنجاح",
    'review-send-success': 'تم إرسال تقييمك بنجاح. شكرا لتقييمك.',
    'ok': 'حسنا',
    filter: {
      'newest': 'الأحدث',
      'with-photos': 'مع الصور',
      'negative-first': 'السلبي أولا',
      'positive-first': 'الإيجابي أولا',
    },
    'review-not-found-negative-first': 'لم يتم العثور على تقييم سلبي',
    'review-not-found': 'لم يتم العثور على تقييم',
    'review-not-found-recommended': 'لم يتم العثور على تقييم موصى به',
    'review-not-found-with-photos': 'لم يتم العثور على تقييم مع الصور',
    'something-wrong': 'حدث خطأ ما ، هل يمكنك المحاولة مرة أخرى؟',
    'question-send-success': 'تم إرسال سؤالك بنجاح.<br/> شكرا لسؤالك.',
    'answer-send-success': 'تم إرسال إجابتك بنجاح.<br/> شكرا لإجابتك.',
    'like-count': '%{count} إعجاب',
    'like': 'إعجاب',
    'please-select-location': 'الرجاء تحديد الموقع'
  },

  "th": {
    review: {
      owner: "เจ้าของ",
      tenant: "ผู้เช่า",
      "former-resident": "ผู้อาศัยเดิม",
      "real-estate-agent": "ตัวแทนอสังหาริมทรัพย์"
    },
    rating: {
      excellent: "ยอดเยี่ยม",
      satisfied: "พอใจ",
      average: "ปานกลาง",
      poor: "พอใช้",
      terrible: "แย่"
    },
    ratingType: {
      'connectivity_rating': 'การขนส่งและการเดินทาง',
      'lifestyle_rating': 'มาตรฐานการครองชีพและไลฟ์สไตล์',
      'safety_rating': 'ความปลอดภัย',
      'environment_rating': 'สิ่งแวดล้อม'
    },
    ratingTypeSubName: {
      'connectivity_rating': 'การขนส่งสาธารณะและสถานการณ์การจราจรเป็นอย่างไร',
      'lifestyle_rating': 'คุณจะอธิบายคุณภาพชีวิตโดยรวมและบริการที่มีอยู่อย่างไร',
      'safety_rating': 'คุณรู้สึกปลอดภัยตลอดเวลาหรือไม่',
      'environment_rating': 'คุณจะให้คะแนนความสะอาดและคุณภาพสิ่งแวดล้อมอย่างไร'
    },
    year: "%{year} ปี",
    years: "%{year} ปี",
    "more-than-years": "มากกว่า %{year} ปี",
    "image-invalid": "รูปภาพไม่ถูกต้อง กรุณาลบออก",
    "district": "อำเภอ",
    "province": "จังหวัด",
    "city": "เมือง",
    'dropzone': {
      'file-too-big': "ไฟล์ใหญ่เกินไป กรุณาอัปโหลดไฟล์น้อยกว่า %{size} MB",
      'invalid-file-type': "ชนิดของไฟล์ไม่ถูกต้อง กรุณาอัปโหลดไฟล์รูปภาพเท่านั้น",
      'max-file-upload': "คุณสามารถอัปโหลดได้สูงสุด %{files} ไฟล์"
    },
    success: "สำเร็จ",
    'review-send-success': 'ส่งรีวิวของคุณสำเร็จแล้ว\n ขอบคุณสำหรับการรีวิวของคุณ',
    'ok': 'ตกลง',
    filter: {
      'newest': 'ล่าสุด',
      'with-photos': 'มีรูปภาพ',
      'negative-first': 'รีวิวเชิงลบ',
      'positive-first': 'รีวิวเชิงบวก',
    },
    'review-not-found-negative-first': 'ไม่พบรีวิวความคิดเห็นเชิงลบ',
    'review-not-found': 'ไม่พบรีวิว',
    'review-not-found-recommended': 'ไม่พบรีวิวที่แนะนำ',
    'review-not-found-with-photos': 'ไม่พบรีวิวที่มีรูปภาพ',
    'something-wrong': 'เกิดข้อผิดพลาด กรุณาลองอีกครั้ง',
    'question-send-success': 'ส่งคำถามของคุณสำเร็จแล้ว <br/> ขอบคุณสำหรับคำถามของคุณ',
    'answer-send-success': 'ส่งคำตอบของคุณสำเร็จแล้ว <br/> ขอบคุณสำหรับคำตอบของคุณ',
    'like-count': '%{count} คะแนน',
    'like': 'คะแนน',
    'please-select-location': 'กรุณาเลือกสถานที่'
  },
});

i18nProperty.defaultLocale = "en";